/* ResponsiveIframe.module.scss */
.responsiveIframeContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    padding-top: 30.25%; /* Default aspect ratio 16:9 */
    border-radius: 10%; /* Oval shape */
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .responsiveIframeContainer {
        padding-top: 10%; /* Adjust aspect ratio for increased height on mobile */
        border-radius: 15%; /* Optionally adjust border-radius for mobile */
    }
}
